@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Button {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.55em;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		display: inline-block;
	}
	&.disabled,
	&.locked {
		opacity: 0.4;
		cursor: not-allowed;		
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-loading.svg');
			background-size: auto 80%;
			background-position: center right;
		}	
	}

	&.login {
		@include box-shadow(0, 0.16em, 0.32em, 0, rgba($black, 0.2));
		background-color: $darkYellow;
		width: 7.88em;
		height: 4em;

		span {
			color: $text-dark;
			font-size: 1.66em;
			font-weight: bold;
		}
	}
	&.logout {
		width: 8em;
		color: $text-dark;
		background-color: $offwhite;
		border-radius: 0.25em;
		@include box-shadow(0, 0.16em, 0.32em, 0, $black);
		span {
			font-size: 1.8em;
		}
	}

	&.settings {
		width: 2.5em;
		height: 2.5em;
		background-image: url('../../../assets/images/icons/icon-settings.svg');
	}

	&.materials {
		width: 19.25em;
		height: 10em;
		margin: 1.78em auto 0 auto;
		padding-top: 1em;
		border-radius: 0.25em;
		background-image: url('../../../assets/images/icons/icon-download.svg'), linear-gradient(#F5B400, $darkYellow 8%, $darkYellow 92%, #EDAE00 100%);
		background-size: 1.7em auto, 100% 100%;
		background-position: bottom 1em center, center center;
		@include flex('center', 'flex-start');
		@include box-shadow(0, 0.166em, 0.166em, 0, rgba($black, 0.23));
		span {
			font-size: 2.17em;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 1.2;
			text-decoration: none;
			color: $text-dark;	
		}

		&.small {
			span {
				font-size: 1.9em;
			}
		}
	}

	&.yellowGradient {
		width: 8em;
		height: 3.3em;
		background-image: linear-gradient($yellow 5%, $darkYellow 20%, $darkYellow 82%, $yellow 96%);
		padding: 0.5em;
		border-radius: 0.2em;

		@include box-shadow(0, 0.166em, 0.166em, 0, rgba($black, 0.23));

		span {
			color: $text-dark;
			font-size: 2em;
			font-weight: normal;
			text-transform: uppercase;
		}
	}

	&.wide {
		width: 100%;
	}
	&.bold {
		span {
			font-weight: bold;
		}
	}
	
	&.next,
	&.prev {
		width: 10em;
		height: 4.3em;
		border-radius: 0;
		background-image: url('../../../assets/images/button-next.svg');
		background-size: 100% auto;
		padding-bottom: 0.2em;
		padding-right: 0.75em;
		span {
			font-size: 2em;
			color: $blueDark;
			text-transform: uppercase;
			font-weight: bold;
		}
		&.small-text {
			span {
				font-size: 1.2em;
			}
		}
	}
	&.prev {
		background-image: url('../../../assets/images/button-prev.svg');
		padding-right : 0;
		padding-left: 0.75em;
	}	

	&.offWhite {
		background-color: $offwhite;
		border-radius: 0.25em;
		height: 100%;
		width: 100%;

		@include box-shadow(0, 0.16em, 0.32em, 0, $black);

		span {
			font-size: 1.8em;
		}

		&.confirmed {
			background-color: $blueDark;
			color: $white;
		}

		&.marked {
			background-color: $blueSteel;
		}
	}

	&.download {
		width: 100%;
		margin: 1.5em 0;
		height: 3.5em;
		background-image: url('../../../assets/images/icons/icon-download.svg'), linear-gradient(#F5B400, $darkYellow 8%, $darkYellow 92%, #EDAE00 100%);
		background-size: 1.5em auto, 100% 100%;
		background-position: right 1em center, center center;		span {
			font-weight: bold;
		}		
		@include box-shadow(0, 0.166em, 0.166em, 0, rgba($black, 0.23));
	}

	&.showWinner {
		width: 16.5em;
		height: 16em;
		background-image: url('../../../assets/images/button-reveal-winner.svg');
		@include origin(50%, 50%);
		-webkit-animation: animateHeart 1.4s linear 1s infinite;
		animation: animateHeart 1.6s linear 1s infinite;
		&.loading {
			&::after {
				background-image: none;
			}

			
			-webkit-animation: spin 0.5s ease-in-out infinite;
			animation: spin 0.5s linear infinite;
		}
	}
}
@-webkit-keyframes animateHeart {
  0% {
    @include scale(1);
  }
  5% {
    @include scale(1.1);
  }
  10% {
    @include scale(1);
  }
  15% {
    @include scale(1.2);
  }
  30% {
    @include scale(1);
  }
  100% {
    @include scale(1);
  }
}
@keyframes animateHeart {
  0% {
    @include scale(1);
  }
  5% {
    @include scale(1.1);
  }
  10% {
    @include scale(1);
  }
  15% {
    @include scale(1.2);
  }
  30% {
    @include scale(1);
  }
  100% {
    @include scale(1);
  }
}


@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	0% {
		transform: rotate(360deg);
	}
}