@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Intro {
	position: relative;
	width: 100%;
	height: 100%;
	background-position: bottom center;	
	&.act-1 {
		background-image: url('../../assets/images/background-act-1-a.svg'), url('../../assets/images/background-act-1-b.png');
	}
	&.act-2 {
		background-image: url('../../assets/images/background-act-2-a.svg'), url('../../assets/images/background-act-2-b.png');
	}
	&.act-3 {
		background-image: url('../../assets/images/background-act-3-a.svg'), url('../../assets/images/background-act-3-b.png');	
	}

	.Intro-title {
		color: $offwhite;
		font-size: 3.89em;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		padding-top: 1.6em;
	}

	.Intro-videoWrap {
		position: relative;
		width: 60em;
		height: calc(0.5625 * 60em);
		margin: auto;
		margin-top: 3em;
		.Intro-video {
			width: 100%;
			height: 100%;
			&.loading,
			&.ready,
			&.ended {
				@include opacity(0);
			}
		}
		.Intro-videoPlayBtn {
			position: absolute;
			top: calc(50% - 4em);
			left: calc(50% - 4em);
			width: 8em;
			height: 8em;
			background-image: url('../../assets/images/icons/icon-play.svg');
			cursor: pointer;	
			&.loading {cursor: default;}
			&.playing {
				display: none;
			}	
		}
	}
}