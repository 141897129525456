@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Stats {
	position: relative;
	width: 100%;
	height: 100%;
	color: black;
	background-color: white;
	padding: 1em 2em;
	overflow: auto;
	.Stats-backToGameBtn {
		position: absolute;
		top: 1em;
		left: 2em;
		color: black;
	}
	.Stats-logoutBtn {
		position: absolute;
		top: 1em;
		right: 1em;
	}
	.Stats-title {
		text-align: center;
		margin-bottom: 2em;
		span {
			font-size: 2em;
			font-weight: bold;
		}
	}
	.Stats-users,
	.Stats-games {
		span {
			display: inline-block;
			vertical-align: middle;
			font-size: 1.25em;
			&:first-of-type {
				width: 15em;
			}
		}
	}

	.Stats-table {
		margin-top: 2em;
		font-size: 1.25em;
		.Stats-cell {
			display: inline-block;
			vertical-align: middle;
			width: 15em;
		}
		.Stats-tableHeader {
			font-weight: bold;
			.Stats-cell {
				&.email,
				&.created {
					cursor: pointer;
					
				}
			}
		}
	}
}