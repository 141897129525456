@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Game {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../assets/images/background-glow.png'), url('../../assets/images/background-floor.svg'), url('../../assets/images/background-wall.svg');
	background-size: 70em 60em, 100% auto, cover;
	background-position: top -5em center, bottom center, center center;
	background-repeat: no-repeat, no-repeat, repeat-y;

	.Game-audience {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 10.61em;
		background-image: url('../../assets/images/audience.svg');
		background-position: top center;
		@include fadeIn('0.4s', 'ease-out', '0.8s');
	}

	.Game-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.Game-sidebar {
		position: absolute;
		top: 0;
		width: 22em;
		height: 100%;
		.Game-settingsBtn {
			position: absolute;
			top: 1em;
			right: 1em;
		}
		.Game-logoutBtn {
			position: absolute;
			bottom: 4em;
			left: 1em;
		}
		&.open-wide {
			width: 16.92em;
		}
		&.left {
			left: 0;
		}
		&.right {
			right: 0;
		}
	}
}