@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Casting {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1.5em 22em 0 22em;

	.Casting-title {
		text-align: center;
		span  {
			font-size: 2.78em;
			font-weight: bold;
		}
	}
	.Casting-selectedCast {
		padding-left: 5em;
		margin-bottom: 1em;
		.Casting-selectedInstructorAndScenographer,
		.Casting-selectedActors {
			text-align: left;
			display: inline-block;
			vertical-align: top;
			.Casting-selectedPerson {
				span {
					display: inline-block;
					vertical-align: top;
					font-size: 1.67em;
					font-weight: bold;
					white-space: nowrap;
				}
			}
		}
		.Casting-selectedInstructorAndScenographer {
			width: 25em;
			margin-right: 2em;
			.Casting-selectedPerson {
				span:first-of-type {
					width: 5.25em;
				}
			}
		}
		.Casting-selectedActors .Casting-selectedPerson {
			span:first-of-type {
				margin-right: 0.25em;
			}
		}
	}

	.Casting-lists {
		@include flex('center', 'flex-start', 'row');
		.Casting-listWrapper {
			margin: 0 1.5em;

			.Casting-listTitle {
				@include flex('center', 'center');
				width: 16.72em;
				height: 3.06em;
				span {
					font-size: 2.2em;
					font-weight: bold;
				}
			}			

			.Casting-listContent {
				position: relative;
				padding: 1.33em 1em 1.78em 0.6em;
				background-color: rgba($white, 0.32);
				height: 43em;
	
				.Casting-personList {
					position: relative;
					width: 100%;
					height: 100%;
					text-align: center;
					@include custom-scrollbar(1.5em, rgba($offwhite, 0.4), rgba($offwhite, 0.4));
					overflow-y: scroll;
	
					// Double linear gradient that fades towards bottom but keeps a slim line full on the right, for the scrollbar
					-webkit-mask-image: 
						linear-gradient($black 75%, rgba($black, 0) 95%), 
						linear-gradient(to right, rgba($black, 0) 88%, $black 10%);
					
					mask-image: 
						linear-gradient($black 75%, rgba($black, 0) 95%), 
						linear-gradient(to right, rgba($black, 0) 88%, $black 10%);
	
					
				
					.Casting-personWrap {
						position: relative;
						width: 100%;
						margin-bottom: 1em;
						text-align: center;
						cursor: pointer;
						&.last {
							margin-bottom: 5em;
						}
						.Casting-person {
							position: relative;
							width: 9.32em;
							height: 11.71em;
							margin: 0 auto;
							background-size: contain;
							padding: 0.6em;
							transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
							@include cast();
							span {
								color: $text-dark;
								position: absolute;
								top: 0.5em;
								left: 0.5em;
								font-size: 0.83em;
							}

							&.taken {
								opacity: 0.4;
								cursor: not-allowed;
							}

							&.selected {
								width: 11.28em;
								height: 14.11em;
							}
							&.loading {
								&::after {
									content: '';
									width: 100%;
									height: 100%;
									position: absolute;
									top: 0;
									left: 0;
									pointer-events: none;
									background-image: url('../../assets/images/icons/icon-loading.svg');
									background-size: 3em auto;
									background-position: center center;
								}
							}
						}
					}
				}
			}

			&.instructor .Casting-listTitle {
				background-color: $green;
				
			}
			&.scenographer .Casting-listTitle {
				background-color: $blueLight;
			}
			&.actors .Casting-listTitle {
				background-color: $darkerYellow;
			}
		}
	}
}