@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Attention {
	position: relative;
	height: 100%;
	width: 100%;
	background-position: bottom center;
	
	&.act-1 {
		background-image: url('../../assets/images/background-act-1-a.svg'), url('../../assets/images/background-act-1-b.png');
	}
	&.act-2 {
		background-image: url('../../assets/images/background-act-2-a.svg'), url('../../assets/images/background-act-2-b.png');
	}
	&.act-3 {
		background-image: url('../../assets/images/background-act-3-a.svg'), url('../../assets/images/background-act-3-b.png');	
	}

	.Attention-title {
		@include flex('center', 'center');

		padding-top: 10.4em;

		span {
			font-size: 2.8em;
			font-weight: bold;
		}
	}
}