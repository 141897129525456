@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Menu {
	width: 19.74em;
	margin: 1em auto 0 auto;
	padding: 1.33em;
	border-radius: 0.25em;
	background-color: rgba($blueDark, 0.85);
	@include box-shadow(0, 0.167em, 0.33em, 0, $black);
	pointer-events: auto;
	.Menu-items {
		border-radius: 0.25em;
		background-color: $offwhite;
		padding: 0.5em 0.5em 0.5em 0.9em;
		@include box-inner-shadow(0, 0.167em, 0.33em, 0, $blueDark);

		.Menu-startOrFinish {
			color: $blueDark;
			margin-bottom: 0.75em;
			&.finish {
				margin-bottom: 0.25em;
			}
			&.future {color: $blueSteel;}
			.Menu-startOrFinishTitle {
				font-size: 2.22em;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
		.Menu-act {
			color: $blueDark;
			margin-bottom: 0.75em;
			&.future {color: $blueSteel;}
			.Menu-actTitle {
				font-size: 2.22em;
				font-weight: bold;
				text-transform: uppercase;
			}
			.Menu-actSubtitle {
				font-size: 1.39em;
				font-weight: bold;
				text-transform: uppercase;
			}
		}

		.Menu-gameStep {
			position: relative;
			padding-left: 1.4em;
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: calc(50% - 0.45em);
				width: 0.9em;
				height: 0.9em;
				background-image: url('../../../assets/images/menu-bullet-yellow.svg');
			}
			&.active {
				cursor: pointer;
			}
			&.future {
				&::before {
					background-image: url('../../../assets/images/menu-bullet-white.svg');
				}
				color: $blueSteel;
			}
			span {
				font-size: 1.39em;
				font-weight: bold;
				white-space: nowrap;
			}
		}
	}
}