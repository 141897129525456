@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CompanyLogos  {
	width: 60em;
	@include flex('space-between', 'center');
	.CompanyLogos-danskTeater  {
		background-image: url(../../../assets/images/logo-dansk-teater.svg);
		width: 13.5em;
		height: 7.8em;
	}
	.CompanyLogos-teaterCentrum {
		background-image: url(../../../assets/images/logo-teater-centrum.png);
		width: 10em;
		height: 6em;
	}
	.CompanyLogos-cgl  {
		background-image: url(../../../assets/images/logo-cgl.svg);
		width: 13.5em;
		height: 7.8em;
		cursor: pointer;
	}
	.CompanyLogos-apMollerFonden {
		background-image: url(../../../assets/images/logo-ap-moller-fonden.png);
		width: 15em;
		height: 7.8em;
	}
}