@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($blueDark, 0.7);
	backdrop-filter: blur(1px);
	@include flex('center', 'center');

	.Popup-content {
		position: relative;
		width: 37.3em;
		background-color: $offwhite;
		border-radius: 0.5em;
		padding: 3.2em 2.5em 2.44em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba($black, 0.2));

		.Popup-closeButton {
			cursor: pointer;
			position: absolute;
			right: 1em;
			top: 1em;
			width: 2.66em;
			height: 2.66em;
			background-image: url(../../../assets/images/icons/icon-cross.svg);
		}

		.Popup-title {
			color: $text-dark;
			margin-bottom: 2.2em;
			span {
				font-size: 2.8em;
				text-transform: capitalize;
			}
		}
		.Popup-text {
			margin-bottom: 3.2em;
			p {
				color: $text-dark;
				font-size: 1.4em;
				line-height: 1.33;
				&:nth-child(1) {margin-top: 0;}
			}
		}
		.Popup-buttons {
			width: 100%;
			> div {margin: 0 1em;}
			@include flex('center', 'center');
			// &.single {
			// }
		}
	}

	&.warning {
		.Popup-content {
			width: 33.75em;
			background-color: rgba($blueSteel, 0.9);
			.Popup-closeButton { 
				display: none;
			}
			.Popup-title { 
				text-align: center;
				color: $offwhite;
				span {
					font-weight: bold;
				}
			}
			.Popup-text {
				text-align: center;
				p {
					font-size: 1.67em;
					color: $offwhite;
				}
			}
		}
	}
}
