@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PopupDownloads {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($blueDark, 0.7);
	backdrop-filter: blur(1px);
	@include flex('center', 'center');

	.PopupDownloads-content {
		position: relative;
		width: 40em;
		background-color: white; // rgba($blueSteel, 0.9);
		border-radius: 0.5em;
		padding: 3.2em 2.5em 2.5em 2.5em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba($black, 0.2));

		.PopupDownloads-closeButton {
			cursor: pointer;
			position: absolute;
			right: 1em;
			top: 1em;
			width: 2em;
			height: 2em;
			background-image: url(../../../assets/images/icons/icon-cross.svg);
		}
		.PopupDownloads-title { 
			text-align: center;
			color: $text-dark; // $offwhite;
			margin-bottom: 2.2em;
			span {
				font-size: 2.8em;
				font-weight: bold;
			}
		}

		.PopupDownloads-buttons {
			margin: auto;
			width: 25em;
		}
	}
}
