@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Curtains {
	position: absolute;
	width: 100%;
	height: 100%;
	pointer-events: none;
	overflow: hidden;

	.Curtains-left {
		position: absolute;
		left: -38em;
		top: 0;
		height: 100%;
		width: 62em;
		background-image: url('../../../assets/images/curtain-left.svg');
		background-size: auto 100%;
		background-position: top right;
		@include translate(0em);
		@include transition(0.8s, 'all', 'ease-in-out');

		
		&.closed {
			@include translate(35em);
		}
		&.open-wide {
			@include translate(-5em);
		}
	}
	.Curtains-right {
		position: absolute;
		top: 0;
		right: -38em;
		height: 100%;
		width: 62em;
		background-image: url('../../../assets/images/curtain-right.svg');
		background-size: auto 100%;
		background-position: top left;
		@include translate(0em);
		@include transition(0.8s, 'all', 'ease-in-out');

		&.closed {
			@include translate(-35em);
		}
		&.open-wide {
			@include translate(5em);
		}
	}
}