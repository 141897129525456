@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ChooseShow {
	position: relative;
	@include flex('center','center','column');
	width: 100%;
	height: 100%;
	color: $blueDark;
	background-position: bottom center;	
	background-image: url('../../assets/images/background-act-1-a.svg'), url('../../assets/images/background-act-1-b.png');

	.ChooseShow-info  {
		background-color: rgba($offwhite, 0.9);
		padding: 2.5em;
		padding-top: 1.2em;
		padding-bottom: 1.2em;
		
		border-radius: 0.5em;
		margin-bottom: 1.8em;

		width: 59.8em;

		.ChooseShow-infoTitle {
			span {
				font-size: 2.8em;
				font-weight: bold;
			}
		}
		.ChooseShow-infoTextWrapper {
			margin-top: 1.66em;
			.ChooseShow-infoText {
				font-size: 1.4em;
			}
		}
	}

	.ChooseShow-choiceWrapper {
		@include flex('center','','row');
		.ChooseShow-shows {
			@include flex('center','','column');
			background-color: rgba($offwhite, 0.69);
			height: 23em;
			width: 38.6em;
			margin-right: 1.5em;
			border-radius: 0.5em;
			padding: 1.7em;

			.ChooseShow-showTitle {
				@include flex('center');
				margin-bottom: 2.3em;
				span {
					font-size: 2.8em;
					font-weight: bold;
				}
			}

			.ChooseShow-showsWrapper {
				@include flex('','center','column');
				flex-wrap: wrap;
				height: 16em;

				.ChooseShow-show {
					width: 17.1em;
					height: 3.16em;
					margin-bottom: 0.7em;
					
					text-transform: capitalize;
				}
			}
		}

	
		.ChooseShow-genres {
			@include flex('','center','column');
			background-color: rgba($offwhite, 0.69);
			height: 30.7em;
			width: 19.6em;
			border-radius: 0.5em;
			padding: 1.7em;
			
			.ChooseShow-genreTitle {
				@include flex('center');
				margin-bottom: 2.3em;
				span {
					font-weight: bold;
					font-size: 2.8em;
				}
			}

			.ChooseShow-genre {
				width: 17.1em;
				height: 3.16em;
				margin-bottom: 0.7em;

				text-transform: capitalize;
			}
		}	
	}
}
