@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.LoginGroup {
	width: 100%;
	height: 100%;
	margin: 0 auto;

	.LoginGroup-title {
		width: 100%;
		height: 5em;
		@include flex('flex-start', 'center');
		padding: 0 1.5em;

		background-color: $blueDark;
		border-top-left-radius: 0.5em;
		border-top-right-radius: 0.5em;
		span {
			font-size: 1.5em;
			font-weight: bold;
			color: white;
			text-transform: uppercase;
		}
	}
	.LoginGroup-body {
		width: 100%;
		height: calc(100% - 5em);
		background-color: $blueSteel;
		border-bottom-left-radius: 0.5em;
		border-bottom-right-radius: 0.5em;
		padding: 1.7em 1.5em 0 1.5em;
		.LoginGroup-loginDirection {
			margin-bottom: 2.8em;
			@include no-select();
			span {
				font-size: 1.66em;
				color: $text-dark;
			}
		}
		.LoginGroup-form {
			position: relative;
			input {
				display: block;
				font-size: 1.66em;
				line-height: 1;
				width: 100%;
				height: 2.4em;
				border-radius: 0.33em;
				padding: 0.66em;
				margin: auto;
				margin-bottom: 1.4em;
				background-color: $white;
				color: $text-dark;
				font-weight: normal;
				&::placeholder {
					color: rgba($text-dark, 0.5);
				}
			}
			.LoginGroup-buttons {
				@include flex('space-between', 'center');
				> div { 
					margin: 0 0.5em;
					&:nth-child(1) {margin-left: 0;}
					&:nth-last-child(1) {margin-right: 0;}
				}			
				
			}
			.LoginGroup-loginBtn {
				width: 100%;
			}
			
			.LoginGroup-errorMessage {
				height: 1.5em;
				margin-top: 1em;
				margin-bottom: 0.6em;
				padding: 0 1em;
				font-size: 1.11em;
				color: $text-dark;
				text-align: center;
			}
		}	
	}

	.LoginGroup-groups {
		.LoginGroup-groupBtn {
			display: inline-block;
			vertical-align: top;
			width: 10em;
			height: 11.74em;
			margin-right: 1em;
			margin-bottom: 0.25em;
			cursor: pointer;
			&:nth-child(2),
			&:nth-child(4),
			&:nth-child(6) {
				margin-right: 0;
			}
			&:nth-child(5),
			&:nth-child(6) {
				margin-bottom: 0;
			}
		}
	}

}