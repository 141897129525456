@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Assignment {
	position: relative;
	width: 100%;
	height: 100%;
	background-position: bottom center;
	@include flex('center', 'center');
	&.act-1 {
		background-image: url('../../assets/images/background-act-1-a.svg'), url('../../assets/images/background-act-1-b.png');
	}
	&.act-2 {
		background-image: url('../../assets/images/background-act-2-a.svg'), url('../../assets/images/background-act-2-b.png');
	}
	&.act-3 {
		background-image: url('../../assets/images/background-act-3-a.svg'), url('../../assets/images/background-act-3-b.png');	
	}

	.Assignment-box {
		width: 37.25em;
		margin: auto;
		padding: 2.65em;
		border-radius: 0.56em;
		color: $blueDark;
		background-color: rgba($offwhite, 0.9);		

		.Assignment-title, 
		.Assignment-subtitle {
			font-size: 2.75em;
			line-height: 1.2;
			font-weight: bold;
		}
		.Assignment-text {
			margin-top: 2.39em;
			p,
			li {
				font-size: 1.39em;
				line-height: 1.2;
				margin-top: 0;
			}
		}
	}


}