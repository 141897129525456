/* Colors */
$pink: #D8016B;
$purple: #793D90;
$green: #7FB979;
$blue: #0089D9;

$yellow: #FFE49A;
$darkYellow: #FACA47;
$darkerYellow: #D8A90B;

$black: #000000;
$white: #ffffff;
$offwhite: #F2F2F2;

$blueLight: #5BBABA;
$blueDark: #24363C;
$blueDarker: #081A1F;
$blueSteel: #8EA2A8;

$red: #EF001F;
$redDark: #8A192D;

$text-dark: #24363C;
$text-light: #FCFAF0;


/* Group colors */
$teaterDenGladeKo: $darkYellow;
$detSoedeTeater: $pink;
$skovTeatret: $green;
$blaaKat: $blue;
$monkeyTonkey: $red;
$detSkaeveTeater: $purple;