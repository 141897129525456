@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GroupLogin {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0 22em;
	.GroupLogin-groups {
		width: 50em;
		margin: 6em auto 0;
		.GroupLogin-group {
			display: inline-block;
			vertical-align: top;
			width: 15.5em;
			height: 18.2em;
			margin-right: 1em;
			margin-bottom: 1em;
			&:nth-child(3),
			&:nth-child(6) {
				margin-right: 0;
			}
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				margin-bottom: 0;
			}
		}	
	}
}