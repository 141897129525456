@mixin noAnimation() {
  -webkit-animation: none;
  animation: none;
}

@mixin animateCorrect() {
  -webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  -webkit-animation-fill-mode: forwards;
  animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  animation-fill-mode: forwards;
}

@mixin animateWrong() {
  -webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  -webkit-animation-fill-mode: forwards;
  animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  animation-fill-mode: forwards;
}

@mixin animatePow() {
  -webkit-animation: pow 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  animation: pow 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
}

@mixin fadeIn($seconds: '0.5s', $timing: 'ease', $delay: '0s') {
  @include opacity(0);
  animation: fadeIn #{$seconds} #{$timing} #{$delay} forwards;
  animation: fadeIn #{$seconds} #{$timing} #{$delay} forwards;
}

@mixin fadeOut($seconds: '0.5s', $timing: 'ease', $delay: '0s') {
  @include opacity(1);
  animation: fadeOut $seconds #{$timing} $delay forwards;
  -webkit-animation: fadeOut $seconds #{$timing} $delay forwards;
}

@mixin pulse($seconds: '2s') {
  @include opacity(0.75);
  animation: pulse $seconds infinite;
  -webkit-animation: pulse $seconds infinite;
}

@mixin delayButton($seconds: 0.5s, $timing: 'ease', $delay: 0.5s) {
  @include opacity(0);
  pointer-events: none;
  cursor: default;
  animation: delayButton $seconds #{$timing} $delay forwards;
  -webkit-animation: delayButton $seconds #{$timing} $delay forwards;
}


@keyframes delayButton {
	0% {
    @include opacity(0);
    pointer-events: none;
    cursor: default;
  }
  99% {
    @include opacity(1);
    pointer-events: none;
    cursor: default;
  }
	100% {
    @include opacity(1);
    pointer-events: all;
    cursor: pointer;
  }
}
@-webkit-keyframes delayButton {
	0% {
    @include opacity(0);
    pointer-events: none;
    cursor: default;
  }
  99% {
    @include opacity(1);
    pointer-events: none;
    cursor: default;
  }
	100% {
    @include opacity(1);
    pointer-events: all;
    cursor: pointer;
  }
}

@keyframes pulse {
	0% {@include opacity(1);}
  50% {@include opacity(0.1);}
	100% {@include opacity(1);}
}
@-webkit-keyframes pulse {
	0% {@include opacity(1);}
  50% {@include opacity(0.1);}
	100% {@include opacity(1);}
}

@-webkit-keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}
@keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}

@-webkit-keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}

@-webkit-keyframes pow {
	0% {@include opacity(1); @include scale(0);}
  20% {@include scale(1.1);}
	30% {@include scale(0.8);}
  40% {@include scale(1);}
  99% {@include scale(1); @include opacity(1);}
  100% {@include scale(1); @include opacity(1);}
  100% {@include opacity(0); @include scale(1);}
}

@keyframes pow {
	0% {@include opacity(1); @include scale(0);}
  20% {@include scale(1.1);}
	30% {@include scale(0.8);}
  40% {@include scale(1);}
  99% {@include scale(1); @include opacity(1);}
  100% {@include scale(1); @include opacity(1);}
  100% {@include opacity(0); @include scale(1);}
}

@-webkit-keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
  }

  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
  }

  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutToLeft {
  from {@include translate(0);}
  to {@include translate(-100vw);}
}
@-webkit-keyframes slideOutToLeft {
  from {@include translate(0);}
  to {@include translate(-100vw);}
}


@keyframes slideInFromLeft {
  from {@include translate(-100vw);}
  to {@include translate(0);}
}
@-webkit-keyframes slideInFromLeft {
  from {@include translate(-100vw);}
  to {@include translate(0);}
}