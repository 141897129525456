@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Presentation {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0 22.75em;

	.Presentation-light {
		pointer-events: none;
		position: absolute;
		left: 0;
		right: 0;
		height: 89%;
		filter: blur(0.75em);
		
		.triangle-left {
			position: absolute;
			width: 100%;
			height: 100%;
			@include opacity(0.5);
			background: linear-gradient(to bottom, #FFFDD3, rgba(#FFFDD3, 0) 110%);
			@include transition(1s, 'clip-path');
			
			clip-path: polygon(35% 100%, 100% -5%, 73% 100%);
			&.closed {
				
				clip-path: polygon(35% 100%, 50% -5%, 73% 100%);
			}

			&.away {
				clip-path: polygon(100% 100%, 100% -5%, 100% 100%);
			}
		}
		.triangle-right {
			position: absolute;
			width: 100%;
			height: 100%;
			@include opacity(0.5);
			background: linear-gradient(to bottom, #FFFDD3, rgba(#FFFDD3, 0) 110%);
			@include transition(1s, 'clip-path');
			
			clip-path: polygon(27% 100%, 0% -5%, 65% 100%);

			&.closed {
				clip-path: polygon(27% 100%, 50% -5%, 65% 100%);
			}	

			
			&.away {
				clip-path: polygon(0% 100%, 0% -5%, 10% 100%);
			}
		}
		&.away {
			&::after {
				@include opacity(0);
			}
		}

		&::after {
			content: '';
			position: absolute;
			bottom: -5em;
			left: calc(50% - 0.5 * 49.61em);
			width: 49.61em;
			height: 7.17em;
			background-image: url('../../assets/images/background-presentation-2.svg');
			@include transition(0.5s, 'opacity');
		}
	}
	
	.Presentation-groups {
		position: absolute;
		top: 4.1em;
		left: 22.75em;
		width: calc(100% - 2 * 22.75em);
		text-align: center;
		.Presentation-group {
			display: inline-block;
			vertical-align: top;
			width: 9.82em;
			height: 36em;
			margin-right: 0.25em;
			&:nth-child(6) {margin-right: 0;}
			&.faded {
				@include opacity(0.31);
			}
			&.hide {
				@include opacity(0);
			}
			&.selectable {
				cursor: pointer;
			}
		}	
	}

	.Presentation-selectedGroup {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.Presentation-selectedGroupBadge {
			position: absolute;
			bottom: 7%;
			left: calc(50% - 0.5 * 17.25em);
			width: 17.25em;
			&.act-1 {
				width: 16.215em;
				left: calc(50% - 0.5 * 16.215em);
			}
		}
	}

	.Presentation-feedbackOptions {
		position: absolute;
		top: 10.42em;
		left: 17.94em;
		width: calc(100% - 2 * 17.94em);
		height: 23.31em;
		@include fadeIn(0.75s, ease, 1s);

		.Presentation-feedbackOption {
			position: absolute;
			width: 10.89em;
			height: 10.07em;
			color: $blueDark;
			padding: 0 1em 2.25em 1em;
			cursor: pointer;
			@include flex('center', 'center');
			svg {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
			span {
				position: relative;
				text-align: center;
				font-size: 1.67em;
				font-weight: bold;
				line-height: 1;
			}

			&:nth-child(1) {top: 0; left: 0;}
			&:nth-child(2) {top: 0; left: 13.89em;}
			&:nth-child(3) {top: 0; right: 13.89em;}
			&:nth-child(4) {top: 0; right: 0;}
			&:nth-child(5) {top: 13.06em; left: 0;}
			&:nth-child(6) {top: 13.06em; left: 13.89em;}
			&:nth-child(7) {top: 13.06em; right: 13.89em;}
			&:nth-child(8) {top: 13.06em; right: 0;}			

			&.selected {
				&.teater-den-glade-ko {svg path {stroke: $teaterDenGladeKo;}}
				&.det-soede-teater {svg path {stroke: $detSoedeTeater;}}
				&.skov-teatret {svg path {stroke: $skovTeatret;}}
				&.blaa-kat {svg path {stroke: $blaaKat;}}
				&.monkey-tonkey {svg path {stroke: $monkeyTonkey;}}
				&.det-skaeve-teater {svg path {stroke: $detSkaeveTeater;}}
			}
			&.act-1 {
				&.feedback-3 span {font-size: 1.5em}
				&.feedback-4 span, 
				&.feedback-5 span {font-size: 1.4em}
				&.feedback-7 span {font-size: 1.2em}
			}
			&.act-2 {
				&.feedback-2 span {font-size: 1.5em}
				&.feedback-4 span, 
				&.feedback-6 span {font-size: 1.4em}
			}
			&.act-3 {
				&.feedback-1 span,
				&.feedback-2 span,
				&.feedback-3 span,
				&.feedback-6 span {font-size: 1.5em}
			}
		}
	}

	.Presentation-feedbackResults {
		position: absolute;
		top: 0em;
		left: calc(50% - 0.5 * 72em);
		width: 72em;

		.Presentation-feedbackResult {
			position: absolute;
			width: 10.89em;
			height: 10.07em;
			color: $blueDark;
			padding: 0 2.5em 2.25em 0.5em;
			animation: scaleBounce 0.6s ease-in-out 1s 1 forwards;
			@include flex('center', 'center');
			@include origin(50%, 50%);
			@include scale(0);
			svg {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
			span {
				position: relative;
				text-align: center;
				font-size: 1.67em;
				font-weight: bold;
				line-height: 1;
				&:last-of-type {
					position: absolute;
					right: 0.25em;
					display: inline-block;
					font-size: 3em;
					line-height: 1em;
					font-weight: bold;
					color: rgba($blueDark, 0.34);
				}
			}

			&.act-1 {
				&:nth-child(1) {
					top: 23em; 
					left: 14em;
					animation-delay: 1s;
				}
				&:nth-child(2) {
					top: 16.44em; 
					right: 15em;
					animation-delay: 2s;
				}
				&:nth-child(3) {
					top: 9.25em; 
					right: 32em;
					animation-delay: 3s;
				}
				&:nth-child(4) {
					top: 39.5em; 
					right: 12em;
					animation-delay: 4s;
				}
				&:nth-child(5) {
					top: 6.5em; 
					left: 8em;
					animation-delay: 5s;
				}
				&:nth-child(6) {
					top: 27.5em; 
					right: 3em;
					animation-delay: 6s;
				}
				&:nth-child(7) {
					top: 38.5em; 
					left: 6em;
					animation-delay: 7s;
				}
				&:nth-child(8) {
					top: 6.5em; 
					right: 4em;
					animation-delay: 8s;
				}
			}

			&.act-2,
			&.act-3 {
				&:nth-child(1) {
					top: 38.5em; 
					left: 15em;
					animation-delay: 1s;
				}
				&:nth-child(2) {
					top: 19.5em; 
					right: 15em;
					animation-delay: 2s;
				}
				&:nth-child(3) {
					top: 6.5em; 
					left: 3em;
					animation-delay: 3s;
				}
				&:nth-child(4) {
					top: 19em; 
					left: 13em;
					animation-delay: 4s;
				}
				&:nth-child(5) {
					top: 40em; 
					right: 10em;
					animation-delay: 5s;
				}
				&:nth-child(6) {
					top: 6em; 
					right: 6em;
					animation-delay: 6s;
				}
				&:nth-child(7) {
					top: 29.5em; 
					left: 3em;
					animation-delay: 7s;
				}
				&:nth-child(8) {
					top: 28em; 
					right: 2em;
					animation-delay: 8s;
				}
	
			}

			

			&.teater-den-glade-ko {svg path {stroke: $darkYellow;}}
			&.det-soede-teater {svg path {stroke: $pink;}}
			&.skov-teatret {svg path {stroke: $green;}}
			&.blaa-kat {svg path {stroke: $blue;}}
			&.monkey-tonkey {svg path {stroke: $red;}}
			&.det-skaeve-teater {svg path {stroke: $purple;}}

			&.act-1 {
				&.feedback-1 span:first-of-type {font-size: 1.35em}
				&.feedback-3 span:first-of-type {font-size: 1.5em}
				&.feedback-4 span:first-of-type {font-size: 1.7em} 
				&.feedback-5 span:first-of-type {font-size: 1.3em}
				&.feedback-7 span:first-of-type {font-size: 1.2em}
			}
			&.act-2 {
				&.feedback-2 span:first-of-type {font-size: 1.4em}
				&.feedback-4 span:first-of-type, 
				&.feedback-6 span:first-of-type {font-size: 1.3em}
				&.feedback-8 span:first-of-type {font-size: 1.4em}
			}
			&.act-3 {
				&.feedback-1 span:first-of-type {font-size: 1.2em}
				&.feedback-2 span:first-of-type {font-size: 1.3em}
				&.feedback-3 span:first-of-type,
				&.feedback-6 span:first-of-type {font-size: 1.5em}
			}
		}
	}
}

@keyframes scaleBounce {
	0% {@include scale(0);}
	25% {@include scale(calc(var(--size, 1) + 0.2));}
	50% {@include scale(calc(var(--size, 1) - 0.1));}
	75% {@include scale(calc(var(--size, 1) + 0.1));}
	100% {@include scale(calc(var(--size, 1)));}
}