@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Instructions {
	width: 19.25em;
	margin: 7em auto 0 auto;
	padding: 1.6em;
	border-radius: 0.25em;
	color: $blueDark;
	background-color: $offwhite;
	@include box-shadow-inset-outset(
		0, 0.167em, 0.33em, 0, rgba($black, 0.42),
		0, 0.167em, 0.33em, 0, $black
	);

	&.open-wide {
		width: 14.17em;
	}
	.Instructions-title {
		span {
			font-size: 1.39em;
			font-weight: bold;
			text-transform: uppercase;
		}
	}
	.Instructions-text {
		p {
			margin-top: 0;
			line-height: 1.22;
			button,
			a {
				font-size: 1.22em;
				font-weight: bold;
				color: inherit;
				text-decoration: none;
				box-shadow: none;
				border-style: none;
				padding: 0;
				margin: 0;
				cursor: pointer;
			}
			em {
				font-size: 1.25em;
			}
		}


	}
}