@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CastingPopup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($blueDark, 0.7);
	backdrop-filter: blur(1px);
	@include flex('center', 'center');

	.CastingPopup-content {
		position: relative;
		width: 37.3em;
		background-color: $offwhite;
		border-radius: 0.5em;
		padding: 3.2em 2.5em 2.44em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba($black, 0.2));

		.CastingPopup-closeButton {
			cursor: pointer;
			position: absolute;
			right: 1em;
			top: 1em;
			width: 2.66em;
			height: 2.66em;
			background-image: url(../../assets/images/icons/icon-cross.svg);
		}

		.CastingPopup-title {
			color: $text-dark;
			margin-bottom: 2.2em;
			span {
				font-size: 2.8em;
			}
		}
		.CastingPopup-text {
			margin-bottom: 1em;
			span {
				color: $text-dark;
				font-size: 1.4em;
			}
		}
		.CastingPopup-roles {
			.CastingPopup-role {
				position: relative;
				padding-left: 2em;
				cursor: pointer;
				span {
					color: $text-dark;
					font-size: 1.4em;
				}
				&::after {
					content: '';
					position: absolute;
					left: 0em;
					top: calc(50% - 0.375em);
					width: 0.75em;
					height: 0.75em;
					border-radius: 100%;
					border: 1px solid $text-dark;
				}
				&.selected::after {
					background-color: $text-dark;
				}
			}
		}
		.CastingPopup-confirmBtn {
			margin-top: 2em;
			@include flex('center', 'center');
		}
	}
}
