@import 'styles/variables';
@import 'styles/fonts';
@import 'styles/mixins';
@import 'styles/animations';
html {
	position: relative;
	height: 100%;
}

body {
	position: relative;
	height: 100%;
	width:100%;
	margin: 0;
	padding: 0;
	font-size: calc(18 * ((100vw / 1920)));
	font-family: 'Helvetica Neue', Helvetica, sans-serif;
	font-weight: normal;
	overflow: hidden;
	color: $offwhite;
	background-color: black;
}

#root {
	height: 100%;
	overflow: hidden;
}

*,
*::after,
*::before {
	box-sizing: border-box;
	font-family: 'Helvetica Neue', Helvetica, sans-serif;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

button {
	background-image: none;
	-webkit-tap-highlight-color: transparent;
	@include no-select();
	&:focus {
		outline: 0;
	}
	&::-moz-focus-inner {
		border: 0;
	}
}

ul, ol {
	padding-inline-start: 1em;
	margin: 0;
}

input,
input:focus,
textarea,
textarea:focus {
	font-size: inherit;
	border-style: none;
	outline: none;
	box-shadow: none;
	@include appearance(none);
}

strong {
	font-weight: bold;
}


@media (min-aspect-ratio: 16/9) {
	body {
		font-size: calc(18 * ((100vh / 1080)));
	}
}