@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameCode {
	width: 19.74em;
	height: 3.33em;
	margin: 1em auto 0 auto;
	background-color: $redDark;
	border-radius: 0.25em;
	@include flex('center', 'center');
	span {
		display: inline-block;
		color: $white;
		font-size: 1.67em;
		font-weight: bold;
		&:first-child {
			text-transform: uppercase;
			margin-right: 0.25em;
		}
	}
}