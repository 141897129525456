@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PopupSettings {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($blueDark, 0.7);
	backdrop-filter: blur(1px);
	@include flex('center', 'center');

	.PopupSettings-content {
		position: relative;
		width: 30em;
		background-color: rgba($blueSteel, 0.9);
		border-radius: 0.5em;
		padding: 3.2em 2.5em 2.5em 2.5em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba($black, 0.2));

		.PopupSettings-closeButton {
			cursor: pointer;
			position: absolute;
			right: 1em;
			top: 1em;
			width: 2.66em;
			height: 2.66em;
			background-image: url(../../../assets/images/icons/icon-cross.svg);
		}
		.PopupSettings-title { 
			text-align: center;
			color: $offwhite;
			margin-bottom: 2.2em;
			span {
				font-size: 2.8em;
				text-transform: capitalize;

				font-weight: bold;
			}
		}

		.PopupSettings-buttons {
			margin: auto;
			width: 18em;

		.PopupSettings-restartButton {
			width: 100%;
		}
		.PopupSettings-logoutButton {
			margin-top: 2em;
			width: 100%;
		}
		}
	}
}
