@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.TitleLogo {
	position: relative;
	z-index: 2;
	@include flex('center','center');
	width: 100%;
	height: 100%;

	.TitleLogo-titleLogo {
		position: absolute;
		z-index: 2;
		width: 100%;
		height: 100%;
		background-image: url(../../../assets/images/title-logo.svg);
		background-size: contain;
	}

	.TitleLogo-radialGradient {
		position: absolute;
		z-index: 1;
		background-image: url(../../../assets/images/radial.png);
		width: 130%;
		height: 130%;
		margin-right: 20%;
	}
}