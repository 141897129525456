@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ChooseShowFacilitator {
	position: relative;
	@include flex('center','center','column');
	width: 100%;
	height: 100%;
	
	color: $blueDark;
	
	background-position: bottom center;	
	background-image: url('../../assets/images/background-act-1-a.svg'), url('../../assets/images/background-act-1-b.png');

	.ChooseShowFacilitator-choicesWrapper {
		@include flex('', '' ,'row');

		.ChooseShowFacilitator-showsWrapper {
			.ChooseShowFacilitator-showTitle {
				@include flex('center', 'center');
				margin-bottom: 1.8em;
				@include no-select();

				span {
					color: $text-light;
					font-size: 2.8em;
					font-weight: bold;
				}
			}
			.ChooseShowFacilitator-shows {
				padding-right: 1.75em;
				.ChooseShowFacilitator-show {
					position: relative;
					background-color: $offwhite;
					width: 17.1em;
					height: 4.3em;
					margin-bottom: 0.8em;
					border-radius: 0.3em;
					@include no-select();
					@include flex('center', 'center');
					span {
						position: relative;
						font-size: 1.8em;
						font-weight: bold;
						&:first-of-type {
							position: absolute;
							width: 100%;
							height: 100%;
							@include opacity(0.6);
						}
					}

					&.active {
						&.teater-den-glade-ko span:first-of-type {background-color: $teaterDenGladeKo;}
						&.det-soede-teater span:first-of-type {background-color: $detSoedeTeater;}
						&.skov-teatret span:first-of-type {background-color: $skovTeatret;}
						&.blaa-kat span:first-of-type {background-color: $blaaKat;}
						&.monkey-tonkey span:first-of-type {background-color: $monkeyTonkey;}
						&.det-skaeve-teater span:first-of-type {background-color: $detSkaeveTeater;}
					}
				}
			}
		}
		.ChooseShowFacilitator-genresWrapper {
			.ChooseShowFacilitator-genreTitle {
				@include flex('center', 'center');
				padding-left: 1.75em;
				margin-bottom: 1.8em;
				@include no-select();

				span {
					color: $text-light;
					font-size: 2.8em;
					font-weight: bold;
				}
			}
			.ChooseShowFacilitator-genres {
				padding-left: 1.75em;
				border-left: solid;
				border-left-color: $offwhite;
				.ChooseShowFacilitator-genre {
					position: relative;
					
					background-color: $offwhite;
					width: 17.1em;
					height: 4.3em;
					margin-bottom: 0.8em;
					border-radius: 0.3em;
					@include no-select();
					@include flex('center', 'center');

					span {
						position: relative;
						font-size: 1.8em;
						font-weight: bold;
						&:first-of-type {
							position: absolute;
							width: 100%;
							height: 100%;
							@include opacity(0.6);
						}
					}
				}
			}
		}
	}
}