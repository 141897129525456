@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Review {
	width: 45.5em;
	height: 53.8em;
	@include flex('center', 'center');
	@include fadeIn('0.5s', 'ease', '0.8s');

	.Review-content {
		width: 100%;
		height: 100%;;
		background-color: $text-light;
		padding: 2.5em 2.5em 0 2.5em;

		.Review-theme {
			border-top: 0.5em solid $blueDarker;
			border-bottom: 0.5em solid $blueDarker;
			height: 4.7em;
			background-image: url('../../../assets/images/title-culture.svg');
			background-position: left center;
			background-size: 12.37em auto;
		}

		.Review-people {
			width: 100%;
			height: 14.75em;
			margin-top: 1.5em;
			@include flex('space-between', 'center');
			.Review-person {
				width: 11.77em;
				height: 100%;
				background-color:#EFEFEE;
				@include cast();
			}
		}

		.Review-title {
			text-align: left;
			margin-top: 1em;
			margin-bottom: 1em;
			white-space: nowrap;
			
			span {
				color: $blueDarker;
				font-size: 3em;
				font-weight: bold;	
				font-family: 'Lora';
				line-height: 1.1;
			}
			&.small span {
				font-size: 2em;
			}
			&.tiny span {
				font-size: 1.7em;
			}
		}

		.Review-text {
			width: 100%;
			min-height: 24em;
			padding-bottom: 1.5em;
			border-bottom: 0.5em solid $blueDarker;
			column-count: 3;
			column-gap: 1em;
			column-fill: balance;
			.Review-textIntro {
				display: inline-block; // makes sure they stay in 1 column
				.Review-blurb {
					min-height: 5.8em;
					p {
						color: $blueDarker;
						margin: 0;
						font-weight: 600;
						line-height: 1.28;
						font-family: 'Lora';
					}
				}
				.Review-subtitle {
					height: 2.6em;
					border-top: 0.1em solid $blueDarker;
					border-bottom: 0.1em solid $blueDarker;
					background-image: url('../../../assets/images/title-theatre.svg');
					background-position: left center;
					background-size: 8.14em auto;
					margin-bottom: 0.93em;
					margin-top: 1.33em;
				}
				.Review-info {
					.Review-infoTitle,
					.Review-infoText {
						p {
							font-size: 0.72em;
							color: $blueDarker;
							margin: 0;
							white-space: nowrap;
							line-height: 1.3;
							font-family: 'Lora';
							a {
								text-decoration: none;
								color: inherit;
							}
						}
					}
					.Review-infoTitle p {font-weight: bold;}
				}
				.Review-stars {
					margin-top: 0.78em;
					margin-bottom: 1em;
					width: 10em;
					height: 1.5em;
					@include flex('space-between', 'center');
					.Review-star {
						width: 1.4em;
						height: 100%;
						background-image: url('../../../assets/images/icons/icon-star.svg');
						background-size: auto 100%;
						&.filled {
							background-image: url('../../../assets/images/icons/icon-star-filled.svg');
						}
					}
				}
			}
			
			.Review-reviewText {
				p {
					font-family: 'Lora';
					margin-top: 0;
					color: $blueDarker;
					line-height: 1.28;
				}
			}

		}
	}
}
