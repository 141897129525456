@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CastingFacilitator {
	position: relative;
	width: 100%;
	height: 100%;
	@include flex('center', 'center', 'row');

	.CastingFacilitator-listWrapper {
		height: 47.13em;
		margin-right: 1.3em;
		margin-left: 1.3em;

		.CastingFacilitator-actorTitle,
		.CastingFacilitator-scenographerTitle,
		.CastingFacilitator-instructorTitle {
			@include flex('center', 'center');
			margin-bottom: 1.6em;
			border-radius: 0.3em;
			height: 3.64em;
			width: 13.2em;

			span {
				font-size: 1.66em;
				font-weight: bold;
			}
		}

		.CastingFacilitator-actorTitle {
			width: 27.16em;
		}

		.CastingFacilitator-actorTitle {
			background-color: $darkerYellow;
		}
		.CastingFacilitator-scenographerTitle {
			background-color: $blueLight;
		}
		.CastingFacilitator-instructorTitle {
			background-color: $green;
		}

		.CastingFacilitator-list {
			height: 34em;
			@include flex('', '', 'column');
			flex-wrap: wrap;

			&.tall {
				height: 42.43em;
			}

			.CastingFacilitator-castPersonWrapper {
				position: relative;
	
				span {
					color: $text-dark;
					position: absolute;
					top: 0.5em;
					left: 0.5em;
					font-size: 0.55em;
				}
				.CastingFacilitator-person {
					@include cast();
					background-size: contain;
					height: 7.9em;
					width: 6.3em;
					padding: 0.6em;
					margin-bottom: 0.55em;
					margin-right: 0.55em;

					&.taken {
						opacity: 0.4;
					}
				}
			}
		}
	}
}