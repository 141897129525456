@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Finish {
	position: relative;
	width: 100%;
	height: 100%;
	@include flex('center', 'center');
	.Finish-showWinnerBtn {
		position: absolute;
		z-index: 10;
		@include fadeIn('0.25s', 'ease-in', '1s');
		&.loading {
			transform: translate3d(1000%, -1000%, 0);
			transition: transform 2s ease-in-out 0s;
			@include fadeOut('2s', 'ease-in', '0s');
		}
	}

	.Finish-groups {
		width: 7.75em;
		height: 53.8em;
		margin-right: 1em;
		.Finish-group {
			width: 100%;
			height: 9.2em;
			cursor: pointer;
			@include opacity(0.3);
			&.selected {
				@include opacity(1);
			}
		}
	}
	.Finish-selectedGroup {
		width: 45.5em;
		height: 53.8em;
	}
}