@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.LandingPage {
	position: relative;
	width: 100%;
	height: 100%;

	.LandingPage-backgroundWrapper {
		position: relative;
		width: 100%;
		height: 100%;
		@include flex('center', 'center', 'column');

		.LandingPage-background {
			position: absolute;
			overflow: hidden;
			@include flex('space-between', '', 'column');
			width: 100%;
			height: 100%;

			.LandingPage-curtain {
				position: absolute;
				top: 0;
				height: 100%;
				width: auto;
				min-width: 100%;
				#theatre-curtain {
					transform-origin: top;
				}
			}

			.LandingPage-floor {
				position: relative;
				width: 100%;
				height: 100%;
				background-image: url('../../assets/images/background-floor.svg');
				background-size: 100% auto;
				background-position: bottom center, top center;
			}
		}

		.LandingPage-titleLogo {
			position: relative;
			width: 40.8em;
			height: 31.5em;
			margin-top: -20em;
		}
	}

	.LandingPage-bottomWrapper  {
		position: absolute;
		@include flex('space-between','flex-end','row');
		width: 100%;
		bottom: 0;
		padding-bottom: 3.5em;
		padding-left: 4.5em;
		padding-right: 3.6em;

		.LandingPage-startButton  {
			padding-bottom: 1.8em;
		}
	}
}