@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.Login {
	height: 100%;
	width: 100%;
	@include flex('center', 'center', 'column');
	
	background-image: url('../../assets/images/background-floor.svg');
	background-size: 100% auto;
	background-position: bottom center;

	.Login-logos {
		position: absolute;
		bottom: 3.5em;
		left: 4.5em;
	}

	.Login-logo {
		position: absolute;
		height: 11.7em;
		width: 15.1em;
		top: 3em;
		right: 4em;
	}
	.Login-wrap {
		position: relative;
		width: 100%;
		.Login-facilitator,
		.Login-group {
			position: relative;
			width: 26.7em;
			height: 33.3em;
			margin: 4em;
		}
	
		@include flex('center', 'center');
	}
}