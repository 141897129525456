@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GroupBadge {
	position: relative;
	width: 17.25em;
	height: 20.25em;
	background-image: url('../../../assets/images/groups/background.svg');
	@include origin(0%, 0%);
	.GroupBadge-logo {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: 90% auto;
		background-position: top 1em center;
		&.teater-den-glade-ko {
			background-image: url('../../../assets/images/groups/teater-den-glade-ko.svg');
		}
		&.det-soede-teater {
			background-image: url('../../../assets/images/groups/det-soede-teater.svg');
		}
		&.skov-teatret {
			background-image: url('../../../assets/images/groups/skov-teatret.svg');
		}
		&.blaa-kat {
			background-image: url('../../../assets/images/groups/blaa-kat.svg');
		}
		&.monkey-tonkey {
			background-image: url('../../../assets/images/groups/monkey-tonkey.svg');
		}
		&.det-skaeve-teater {
			background-image: url('../../../assets/images/groups/det-skaeve-teater.svg');
		}
	}

	.GroupBadge-wrap {
		position: relative;
		.GroupBadge-section {
			text-align: center;
			.GroupBadge-sectionLabel {
				span {
					color: $blueSteel;
					font-weight: bold;
					font-size: 1.67em;
				}
			}
			.GroupBadge-sectionValue {
				span {
					display: inline-block;
					text-transform: uppercase;
					color: $text-dark;
					font-weight: bold;
					font-size: 1.67em;
				}
			}

			&.show {
				.GroupBadge-sectionValue {	
					height: 4.44em;
					@include flex('center', 'center');
					span {
						font-size: 2.22em;
						line-height: 1;
					}
				}
				&.show-3 .GroupBadge-sectionValue {	
					padding: 0 4em;
				}
				&.show-4 .GroupBadge-sectionValue {	
					padding: 0 1em;
				}
				&.show-8 .GroupBadge-sectionValue {	
					padding: 0 2em;
				}
			}

			&.genre {
				.GroupBadge-sectionValue  {
					height: 2.03em;
					@include flex('center', 'center');
				}
				&.genre-6 .GroupBadge-sectionValue  span {
					font-size: 1.3em;
				}	
			}

			&.scenographer,
			&.instructor,
			&.genre,
			&.show {
				margin-bottom: 1.5em;
			}
		}
	}

	/* Display show/genre */
	&.showAndGenre {
		height: 35em;
		padding-top: 20.5em;
		background-image: url('../../../assets/images/groups/background-show.svg');
	}

	/* Display show/genre/people */
	&.showAndPeople {
		height: 51em;
		padding-top: 21em;
		background-image: url('../../../assets/images/groups/background-show-and-people.svg');			
		.GroupBadge-logo {
			background-position: top 1.5em center;
		}
		.GroupBadge-wrap {
			.GroupBadge-section {
				.GroupBadge-sectionLabel {
					span {

						font-size: 1.39em;
					}
				}
				.GroupBadge-sectionValue {
					span {

						font-size: 1.39em;
					}
				}
				&.show {
					margin-bottom: 0.5em;
					.GroupBadge-sectionValue {	
						span {
							font-size: 2.22em;
						}
					}
				}
				&.genre {
					.GroupBadge-sectionValue  {
						height: 1.69em;
					}
					&.genre-6 .GroupBadge-sectionValue span {
						font-size: 1.08em;
					}	
				}
				&.actors {
					.GroupBadge-sectionValue  {
						&.actor-7 span {font-size: 1.3em}
						&.actor-18 span {font-size: 1.3em}
					}
				}

				&.scenographer,
				&.instructor,
				&.genre {
					margin-bottom: 1em;
				}
			}
		}
	}	


	/* Group login: choose group, always basic view */
	&.login-choose-group {
		@include scale(0.58);
	}

	/* Facilitator page: group login, always basic view */
	&.group-login {
		@include scale(0.9);
		&:not(.active) {@include opacity(0.31);}
	}

	/* Group: sidebar badge when logged in as group */
	&.sidebar {
		margin: 2em auto auto auto;
	}

	/* Facilitator page: presentation (groups in background) */
	&.presentation {
		@include scale(0.57);
	}

	/* Facilitator page: presentation (presenting group) */
	&.presentation-focus.act-1 {
		@include scale(0.94);
	}

	&.reviews {
		@include scale(0.45);
	}
}