@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CookieConsentPopup {
	position: absolute;
	width: 100%;
	bottom: 0;
	background: #8EA2A8;
	height: 0;
	overflow: hidden;
	-webkit-animation: slideIn 0.25s linear 0.5s 1 forwards;
	animation: slideIn 0.25s linear 0.5s 1 forwards;
}

.CookieConsentPopup-text {
	height: 100%;
	padding: 0 2em;
	@include flex('center', 'center');
	> span {
		display: inline-block; 
		margin-right: 2em;
		color: $text-dark;
		font-size: 1.5em;
	}

}

@-webkit-keyframes slideIn {
	0% {height: 0;}
	100% {height: 5em;}
}
@keyframes slideIn {
	0% {height: 0;}
	100% {height: 5em;}
}


@media only screen and (max-aspect-ratio: 1/1) {
	.CookieConsentPopup-text {
		padding: 0.5em 10em 0.5em 1em;
		line-height: 1.05;
	}
}