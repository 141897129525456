@font-face {
  font-family: 'Helvetica Neue';
	src: url('../assets/fonts/helvetica-neue-regular.woff');
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
	src: url('../assets/fonts/helvetica-neue-condensed-bold.woff');
  font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Lora';
	src: url('../assets/fonts/lora-regular.ttf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lora';
	src: url('../assets/fonts/lora-bold.ttf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Lora';
	src: url('../assets/fonts/lora-semi-bold.ttf');
	font-weight: 600;
	font-style: bold;
}