@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PrevStepButton {
	position: absolute;
	left: 2.25em;
	bottom: 2.75em;
	pointer-events: all;
	cursor: pointer;
}