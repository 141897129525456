@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	position: relative;
	height: 100%;
	@include flex('center', 'flex-start', '');

	.App-wrap {
		position: relative;
		width: 100%;
		height: 100%;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 100%;
		background-color: black;
	}
}

@media (min-aspect-ratio: 16/9) {
	.App .App-wrap {
		height: 100%;
		width: 106.66em;
	}
}